import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

interface FormLogin {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public formData: FormLogin;
  public error = '';

  constructor(private oauthService: OAuthService, private router: Router) { }

  ngOnInit() {
    this.formData = {
      email: '',
      password: '',
    };

    if (this.oauthService.hasValidAccessToken) {
      this.router.navigate(['/auth']);
    }
  }

  onSubmit = () => {
    this.error = '';
    this.oauthService.fetchTokenUsingPasswordFlow(this.formData.email, this.formData.password).then((e) => {
      this.router.navigate(['/auth']);
    }).catch(() => {
      this.error = 'username / password incorrect';
    });
  }
}
