import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-person-label',
  templateUrl: './person-label.component.html',
  styleUrls: ['./person-label.component.scss']
})

export class PersonLabelComponent implements OnInit {
  @Input() data: object = {};
  @Input() isSelected : boolean = false;
  @Output('onPersonSelect') onPersonSelect: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onClick = () => {
    this.onPersonSelect.emit(this.data)
  }
}
