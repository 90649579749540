import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthComponent } from './pages/auth/auth.component';
import { LabellingComponent } from './pages/labelling/labelling.component';
import { CardsComponent } from './pages/cards/cards.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'auth', component: AuthComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: '/auth/cards', pathMatch: 'full' },
      // { path: 'labelling', component: LabellingComponent },
      { path: 'cards', component: CardsComponent }
    ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
