import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { FetchService } from '../../fetch.service';
import {
  search,
  searchSuccess,
  searchError,
  update,
  updateError,
  updateSuccess,
  updateImage,
  updateImageError,
  updateImageSuccess,
  create,
  createError,
  createSuccess,
} from './actions';

@Injectable()
export class CandidatesEffects {

  constructor(
    private actions: Actions,
    private fetchService: FetchService
  ) { }

  search = createEffect(() => this.actions.pipe(
    ofType(search),
    mergeMap((props) => this.fetchService.get('/candidates', props)
      .pipe(
        map(data => ({ type: searchSuccess.type, payload: data })),
        catchError(() => of({ type: searchError.type }))
      )
    )
  ));

  update = createEffect(() => this.actions.pipe(
    ofType(update),
    mergeMap((props) => {
      console.log(props);
      return this.fetchService.put(`/candidates/${props.id}`, props)
        .pipe(
          map(data => ({ type: updateSuccess.type, payload: data })),
          catchError(() => of({ type: updateError.type }))
        );
    })
  ));

  updateImage = createEffect(() => this.actions.pipe(
    ofType(updateImage),
    mergeMap((props) => this.fetchService.post(`/candidates/${props.id}/picture`, props)
      .pipe(
        map(data => ({ type: updateImageSuccess.type, payload: data })),
        catchError(() => of({ type: updateImageError.type }))
      )
    )
  ));

  create = createEffect(() => this.actions.pipe(
    ofType(create),
    mergeMap((props) => this.fetchService.post(`/candidates`, props)
      .pipe(
        map(data => ({ type: createSuccess.type, payload: data })),
        catchError(() => of({ type: createError.type }))
      )
    )
  ));
}
