import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-time-pagination',
  templateUrl: './time-pagination.component.html',
  styleUrls: ['./time-pagination.component.scss']
})
export class TimePaginationComponent implements OnInit {
  @Input() currentTimestamp: string = '';
  @Output('onPreviousPage') onPreviousPage: EventEmitter<any> = new EventEmitter();
  @Output('onNextPage') onNextPage: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  onPreviousClick = (e: any) => {
    this.onPreviousPage.emit(e);
  }

  onNextClick = (e: any) => {
    this.onNextPage.emit(e);
  }
}
