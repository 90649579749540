import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface FormPerson {
  email: string;
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit {
  @Input() buttonText: string = '';
  @Input() initData: FormPerson;
  @Input() requiredFields: boolean = true;
  @Output('onFormSubmit') onFormSubmit: EventEmitter<any> = new EventEmitter();
  public formData: object;

  constructor() { }

  ngOnInit() {
    this.formData = {
      email: this.initData.email || '',
    };
  }

  onSubmit = () => {
    this.onFormSubmit.emit(this.formData);
  }
}
