import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface ProfileCard {
  body: string;
  id: number;
  title: string;
}

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})

export class ProfileCardComponent implements OnInit {
  @Input() initData: ProfileCard = { body: '', id: 0, title: ''};
  @Output('onDelete') onDelete: EventEmitter<any> = new EventEmitter();
  @Output('onInfoUpdate') onInfoUpdate: EventEmitter<any> = new EventEmitter();
  public profileData: object;

  constructor() { }

  ngOnInit() {
    this.profileData = {
      body: this.initData.body || '',
      id: this.initData.id || 0,
      title: this.initData.title || '',
    };
  }

  onClick = () => {
    this.onDelete.emit(this.profileData)
  }

  onUpdate = () => {
    this.onInfoUpdate.emit(this.profileData)
  }
}
