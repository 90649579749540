import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { search, reset, update, updateImage, create } from '../../store/candidates/actions';
import { selectCandidatesLoading, selectCandidatesData } from '../../store/candidates/selectors';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})

export class CardsComponent implements OnInit {
  public searchData: any = {};
  public createdProfile: boolean = false;
  public selectedProfile: any = null;
  public loadingCandidates$: Observable<boolean>;
  public candidates$: Observable<[]>;

  constructor(private store: Store<{candidates: any, cards: any}>) {}

  ngOnInit() {
    this.store.dispatch(reset());
    this.loadingCandidates$ = this.store.select(selectCandidatesLoading);
    this.candidates$ = this.store.select(selectCandidatesData);

    this.candidates$.subscribe((payload: Array<{cards: any}>) => {
      if (this.selectedProfile && this.selectedProfile.id) {
        payload.forEach((item: any) => {
          if (item.id === this.selectedProfile.id && item.picture !== this.selectedProfile.picture) {
            this.selectedProfile = {...item};
          }
        });
      }

      if (this.createdProfile) {
        this.selectedProfile = {...payload[0]};
        this.createdProfile = false;
      }
    });

  }

  onSearch = (data: any) => {
    const params: any = {};

    if (data.email) { params.email = data.email; }

    this.store.dispatch(search(params));
  }

  onResultClick = (data: any) => {
    this.selectedProfile = {...data};
    window.scrollTo(0, 0);
  }

  onKeydown = (event) =>  {
    if (event.key === 'Enter') {
      this.onUpdateProfile();
    }
  }

  onUpdateProfile = () => {
    this.store.dispatch(update(this.selectedProfile));
  }

  onImageAdded = (event: any) => {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.store.dispatch(updateImage({id: this.selectedProfile.id, image: reader.result}));
      };
    }
  }

  onAddProfile = () => {
    this.store.dispatch(create({email: null}));
    this.createdProfile = true;
  }
}
