import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: string = 'text';
  @Input() required: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
