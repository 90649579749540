import { createSelector } from '@ngrx/store';

const candidates = (state: any) => state.candidates;

export const selectCandidatesData = createSelector(
  candidates,
  (state) => {
    return state.data.map((item: any) => {
      return {
        ...item,
      };
    });
  }
);

export const selectCandidatesLoading = createSelector(
  candidates,
  (state) => state.loading
);
