import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthComponent } from './pages/auth/auth.component';
import { LabellingComponent } from './pages/labelling/labelling.component';
import { CardsComponent } from './pages/cards/cards.component';

import { HeaderComponent } from './components/header/header.component';
import { PersonLabelComponent } from './components/person-label/person-label.component';
import { TimePaginationComponent } from './components/time-pagination/time-pagination.component';
import { FormComponent } from './components/form/form.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { LoaderComponent } from './components/loader/loader.component';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { candidatesReducer } from './store/candidates/reducer';
import { CandidatesEffects } from './store/candidates/effects';

@NgModule({
  declarations: [
    AppComponent,
    LabellingComponent,
    CardsComponent,
    HeaderComponent,
    PersonLabelComponent,
    TimePaginationComponent,
    FormComponent,
    ProfileCardComponent,
    LoaderComponent,
    LoginComponent,
    AuthComponent,
    InputComponent,
    ButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    EffectsModule.forRoot([
      CandidatesEffects,
    ]),
    StoreModule.forRoot({
      candidates: candidatesReducer,
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiBaseUrl],
        sendAccessToken: true
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
