import { createAction, props } from '@ngrx/store';

export const search = createAction('[candidates] Search', props<{
  query?: string;
  email?: string,
}>());
export const searchSuccess = createAction('[candidates] Search Success', props<[]>());
export const searchError = createAction('[candidates] Search Error');

export const update = createAction('[candidates] Update', props<{
  id?: number
}>());
export const updateSuccess = createAction('[candidates] Update Success', props<{}>());
export const updateError = createAction('[candidates] Update Error');

export const updateImage = createAction('[candidates] UpdateImage', props<{
  id?: number
  image?: string | ArrayBuffer
}>());
export const updateImageSuccess = createAction('[candidates] UpdateImage Success', props<{}>());
export const updateImageError = createAction('[candidates] UpdateImage Error');

export const create = createAction('[candidates] Create', props<{}>());
export const createSuccess = createAction('[candidates] Create Success', props<{}>());
export const createError = createAction('[candidates] Create Error');

export const reset = createAction('[candidates] Reset');
