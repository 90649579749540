import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smartglasses-web-labeling-tool';

  constructor(private oauthService: OAuthService) {
    this.oauthService.tokenEndpoint = `${environment.apiBaseUrl}/oauth/token`;
  }
}
