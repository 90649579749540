import { Component, OnInit } from '@angular/core';
import { FetchService } from '../../fetch.service';

@Component({
  selector: 'app-labelling',
  templateUrl: './labelling.component.html',
  styleUrls: ['./labelling.component.scss']
})

export class LabellingComponent implements OnInit {
  public candidatesData: any = [];
  public candidatesLoading: boolean = false;
  public imagesData: any = [];
  public loading: boolean = false;
  public selectedImage: any = {};
  public selectedPreview: string = null;
  public selectedTimestamp: string = '10:30 - 11:30';
  private tmpLoaderF: any = null;

  constructor(private fetchService: FetchService) {}

  ngOnInit() {
    this.fetchImages();
  }

  fetchImages = () => {
    console.log('fetchImages');
    this.loading = true;

    /* Example authenticated fetch
    this.fetchService.fetch('authenticated').then((response) => {
      console.log(response.status);
    });
    */

    // Replace with BE call
    setTimeout(() => {
      this.loading = false;
      this.imagesData = [{
        id: 1,
        candidate: {
          first_name: 'Arnold',
          last_name: 'Schwarzenegger',
          image: 'http://abcnews.go.com/images/Entertainment/gty_arnold_schwarzenegger_jt_130302_wblog.jpg',
        },
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Arnold_Schwarzenegger_-_2019_%2833730956438%29_%28cropped%29.jpg/266px-Arnold_Schwarzenegger_-_2019_%2833730956438%29_%28cropped%29.jpg',
        timestamp: '10:31:04',
      }, {
        id: 2,
        candidate: {
          first_name: 'Chuck',
          last_name: 'Norris',
          image: 'https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTE5NDg0MDU1MjQ5OTc4ODk1/chuck-norris-15720761-1-402.jpg',
        },
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Chuck_Norris_May_2015.jpg/220px-Chuck_Norris_May_2015.jpg',
        timestamp: '10:31:04',
      }, {
        id: 3,
        candidate: {
          first_name: 'Jason',
          last_name: 'Statham',
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Jason_Statham_2007.jpg/266px-Jason_Statham_2007.jpg',
        },
        image: 'https://pixel.nymag.com/imgs/daily/vulture/2015/04/02/02-jason-statham-chatroom.w330.h412.jpg',
        timestamp: '10:31:04',
      }, {
        id: 4,
        candidate: {
          first_name: 'Sylvester',
          last_name: 'Stalone',
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Sylvester_Stallone_by_Gage_Skidmore.jpg/266px-Sylvester_Stallone_by_Gage_Skidmore.jpg',
        },
        image: 'https://m.media-amazon.com/images/M/MV5BMTQwMTk3NDU2OV5BMl5BanBnXkFtZTcwNTA3MTI0Mw@@._V1_UY317_CR6,0,214,317_AL_.jpg',
        timestamp: '10:31:04',
      }, {
        id: 5,
        candidate: null,
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Jet_Li_2009_%28cropped%29.jpg/220px-Jet_Li_2009_%28cropped%29.jpg',
        timestamp: '10:31:04',
      }];
    }, 1500);
  }

  onPreviousTimestamp = (timestamp: any) => {
    console.log('onPreviousTimestamp', timestamp);
  }

  onNextTimestamp = (timestamp: any) => {
    console.log('onNextTimestamp', timestamp);
  }

  onSearchCandidate = (term: string) => {
    console.log('onSearchCandidate', term);
    this.candidatesLoading = true;

    if (this.tmpLoaderF) {
      clearTimeout(this.tmpLoaderF);
    }

    this.tmpLoaderF = setTimeout(() => {
      this.candidatesLoading = false;
      this.candidatesData = [{
        label: 'Andries Demeulenaere | 10-11-1990',
      }, {
        label: 'Wim Van Aarschot | 23-01-1988',
      }];
    }, 2000);
  }

  onSelectCandidate = (candidate: any) => {
    console.log('onSelectCandidate', candidate);
  }

  onSelectImage = (image: any) => {
    this.selectedImage = image;
    this.selectedPreview = null;
  }

  onSelectPreview = (type: string) => {
    this.selectedPreview = type;
  }

  onUpdatePerson = (person: any) => {
    console.log('onUpdatePerson', person);
  }
}
