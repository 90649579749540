import { createReducer, on } from '@ngrx/store';
import {
  search,
  searchSuccess,
  searchError,
  update,
  updateSuccess,
  updateError,
  updateImage,
  updateImageSuccess,
  updateImageError,
  create,
  createSuccess,
  createError,
  reset
} from './actions';

function updateData(data, item) {
  const newData = [...data];

  newData.forEach(obj => {
    if (obj.id === item.id) {
      obj.email = item.email;
      obj.picture = item.picture;
      obj.rekognition_id = item.rekognition_id;
      obj.updated_at = item.updated_at;
    }
  });

  return newData;
}

export const initialState: any = {
  data: [],
  loading: false,
};

export const candidatesReducer = createReducer(initialState,
    on(search, (state, payload) => {
       return {...state, loading: true};
    }),
    on(searchSuccess, (state, payload: any) => {
        return {...state, loading: false, data: payload.payload.data};
    }),
    on(searchError, (state, payload) => {
        return {...state, loading: false};
    }),
    on(update, (state, payload) => {
       return {...state, loading: true};
    }),
    on(updateSuccess, (state, payload: any) => {
        return {...state, loading: false, data: updateData(state.data, payload.payload)};
    }),
    on(updateError, (state, payload) => {
        return {...state, loading: false};
    }),
    on(updateImage, (state, payload) => {
       return {...state, loading: true};
    }),
    on(updateImageSuccess, (state, payload: any) => {
      return {...state, loading: false, data: updateData(state.data, payload.payload)};
    }),
    on(updateImageError, (state, payload) => {
        return {...state, loading: false};
    }),
    on(create, (state, payload) => {
       return {...state, loading: true};
    }),
    on(createSuccess, (state, payload: any) => {
      return {...state, loading: false, data: [payload.payload, ...state.data]};
    }),
    on(createError, (state, payload) => {
        return {...state, loading: false};
    }),
    on(reset, (state, payload) => {
        return {...state, loading: false, data: []};
    }),
);
