import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root'
})
export class FetchService {
  httpOptions: any;

  constructor(private oauthService: OAuthService, private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'bearer ' + this.oauthService.getAccessToken(),
      })
    };
  }

  public get(url: string, params: any = null) {
    const options = {...this.httpOptions};

    if (params) {
      let httpParams = new HttpParams();

      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, params[key]);
      });

      options.params = httpParams;
    }

    return this.http.get(BASE_API_URL + url, options);
  }

  public post(url: string, body: any) {
    return this.http.post(BASE_API_URL + url, body, this.httpOptions);
  }

  public put(url: string, body: any) {
    return this.http.put(BASE_API_URL + url, body, this.httpOptions);
  }

  public delete(url: string) {
    return this.http.delete(BASE_API_URL + url, this.httpOptions);
  }
}
